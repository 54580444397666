body {
  margin: 0;
}
h1.ant-typography {
  margin: 0;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

div.scrollableListContainer {
  height: 22rem;
  width: 100%;
  overflow: auto;
  border: 1px solid rgba(140, 140, 140, 0.35);
}
