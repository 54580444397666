.videoContainer {
  display: flex;
  /* margin: 0 auto; */
  /* justify-content: center; */
  /* width: 80%; */
  height: 22rem;
  /* overflow: hidden; */
}
.videoContainer video {
  height: 22rem;
}
.playerContainer {
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.videoPlayer {
  /* border-radius: 1em; */
  z-index: 0;
}

.controlsContainer {
  z-index: 1;
  margin-top: -5em;
}

.controls {
  display: flex;
  z-index: 1;
  color: white;
  width: 100%;
  /* justify-content: space-between; */
}

.controlButton {
  padding-left: 1rem;
  border: none;
  background: none;
}

.timeline {
  padding-left: 2%;
  width: 98%;
}

.duration {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
}

.controlButton:hover {
  cursor: pointer;
}
